import React from 'react';

/* Components */
import { Container } from '../components/components';

const NotFoundPage = () => (
  <Container subPage>
    <h1>Ups! 404 nie ma takiej strony</h1>
    <p>Sprawdź proszę czy:</p>
    <ol>
      <li>wpisałeś poprawny adres strony</li>
      <li>skorzystaj z menu nawigacyjnego aby dotrzeć do szukanej podstrony</li>
    </ol>
  </Container>
);

export default NotFoundPage;
